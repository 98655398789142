import React, { useEffect } from "react";
import { graphql } from "gatsby";
import ParallaxHeader from "../components/ParallaxHeader";
import { motion } from "framer-motion";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { ImLocation } from "react-icons/im";
import { MdDownload } from "react-icons/md";
import Helmet from "react-helmet";
import projectList from "../../static/uploads/BCSProjectList.pdf";
import Markdown from "markdown-to-jsx";
export const ProjectsTemplate = ({ image, title, intro, projects, list }) => {
  const headerImage = getImage(image) || image;
  useEffect(() => {
    console.log(list);
  }, []);
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <ParallaxHeader className="h-60" image={headerImage} factor={0.7}>
        <motion.h1
          initial={{ x: -10, opacity: 0 }}
          animate={{
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.5,
              type: "ease",
            },
          }}
          className="z-10 text-white text-5xl "
        >
          {title}
        </motion.h1>
      </ParallaxHeader>
      <section className="bg-beige-300/[0.3] flex flex-col items-center w-full justify-center">
        <div className="container p-4  py-10 sm:p-10">
          {intro && (
            <Markdown className="md-content md-theme-color mb-4">
              {intro}
            </Markdown>
          )}
          <a
            className="text-navy hover:text-navyHover underline py-8"
            href={list.publicURL}
            target="_blank"
          >
            <MdDownload className="inline mr-3" />
            Download Project List
          </a>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
            {projects.map((project, idx) => (
              <ProjectCard key={idx} project={project} />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default function ProjectsPage({ data }) {
  const { frontmatter } = data.markdownRemark;

  return (
    <>
      <Helmet>
        <title>Recent Projects - Brown Construction Services</title>
      </Helmet>
      <ProjectsTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        projects={frontmatter.projects}
        list={frontmatter.listPdf}
        intro={frontmatter.intro}
      />
    </>
  );
}

export function ProjectCard({ project }) {
  const logo = getImage(project.logo);
  return (
    <div className="shadow-lg bg-white rounded-sm p-8">
      <div className="flex flex-row items-start pb-5 w-full">
        <div className="">
          <GatsbyImage image={logo} />
        </div>
        <div className="ml-10 flex-grow flex h-full flex-col justify-between">
          <h1 className="text-navy text-2xl mb-2">{project.title}</h1>
          {project.location && (
            <h3>
              <span className="text-navy inline mr-2">
                <ImLocation className="inline" />
              </span>
              {project.location}
            </h3>
          )}
          <hr className="w-[90%] border-b-4 mt-5 border-navy pr-8"></hr>
        </div>
      </div>
      <p className="text-darkbrown/[0.8] ">{project.details}</p>
    </div>
  );
}

export const projectsPageQuery = graphql`
  query ProjectsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        optionalintro
        listPdf {
          publicURL
        }
        projects {
          title
          location
          details
          logo {
            childImageSharp {
              gatsbyImageData(quality: 64, layout: CONSTRAINED, width: 100)
            }
          }
        }
      }
    }
  }
`;
